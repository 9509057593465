import "./EapSupportPhones.scss";
import * as React from "react";
import { GetCompanyBenefitsInfoResponse } from "../../utils/ApiTypes";
import { useTranslation } from 'react-i18next';
import "flag-icons/css/flag-icons.min.css";

interface EapSupportPhonesProps {
    isComponentVisible: boolean;
    benefits: GetCompanyBenefitsInfoResponse;
}

export const EapSupportPhones: React.FC<EapSupportPhonesProps> = (props: EapSupportPhonesProps) => {

    const { t } = useTranslation();

    return(
        <div className="eap-support-phones-component">
            <div className='eap-support-container'>
                <div className='eap-support-title header-4'>{t("Need more support with work or life?")}</div>
                <div className='eap-support-title header-4'>{t("Give us a call and speak to a clinician now.")}</div>
                <div className='phones-component'>
                    {props.benefits.supportPhones.map((phone, i) => {
                        return <a key={i} className='phone-component' href={`tel:${phone.phoneNumber}`}>
                                <div className={"phone-description header-6"}>
                                    {phone.flagName && <span className={`flag fi fi-${phone.flagName.toLowerCase()}`} />}
                                    {phone.description}
                                </div>
                            </a>;
                    })}
                </div>
            </div>
        </div>
    )
}
