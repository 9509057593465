import * as React from 'react';
import {useContext} from "react";
import {
    IonButton,
    IonContent,
    IonPage, isPlatform,
} from "@ionic/react";
import "./SettingsPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import DeleteAccount from "../../components/DeleteAccount/DeleteAccount";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { useTranslation } from 'react-i18next';
import { PRIVACY_POLICY_URL } from '../../utils/utils';

export const SettingsPage: React.FC = () => {
    const {isMobileWidth, isMobileApp} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const isIos = isPlatform("ios");
    const { t } = useTranslation();

    return(
        <IonPage className="settings-page">

            <ResponsiveNavigationMenu title={"Settings"} />

            <IonContent className="settings-page-content">
                <div className="buttons-container">
                    {isMobileApp && <IonButton fill="clear" className="privacy-policy-button" href={PRIVACY_POLICY_URL} target="_blank">
                        {t("Privacy Policyx")}
                    </IonButton>}
                    {isIos &&
                        <DeleteAccount isMobileWidth={isMobileWidth} isComponentVisible={isComponentVisible}/>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
};
