import { SyntheticEvent, useContext } from "react";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../../misc/ApplicationContext";
import "./Tile.scss";

export type GenericTileProps = {
    title: string,
    path: string,
    className?: string
};

function preventDefault(e: SyntheticEvent) {
    e.preventDefault();
}

function noop() {}

export function GenericTile(props: GenericTileProps) {

    const { currentUser } = useContext(ApplicationContext);

    return(
        <div className={"generic-style-component Light-Teal"}>
            <div className={`generic-container ${props.className ?? ''} `}>
                <div className={"title-container"}>
                    {(props.title ?? true) && <div className={"title subtitle2-variant"}>{props.title}</div>}
                </div>
            </div>
        </div>
    )
}