import { IonButton, IonIcon } from '@ionic/react';
import './AskAQuestionButton.scss';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EmailIcon from "../../assets/images/email-blue-gradient.svg";
import { ApplicationContext } from '../../misc/ApplicationContext';
import { ASK_A_QUESTION_PATH } from '../../utils/utils';
import { useHistory } from "react-router-dom";

interface AskAQuestionButtonProps {
    className?: string;
    iconClassName?: string;
}

const AskAQuestionButton: React.FC<AskAQuestionButtonProps> = (props: AskAQuestionButtonProps) => {

    const { currentUser, handleUserError } = useContext(ApplicationContext);
    const { t } = useTranslation();
    const history = useHistory();

    async function handleButtonClick() {
        if(currentUser && currentUser.company.type === "trial"){
            handleUserError(t("Ask a question is not available for Trial users."), " ");
        } else{
            history.push(ASK_A_QUESTION_PATH);
        }
    }

    return (
        <IonButton
            className={`ask-a-question-button-component ${props.className}`}
            onClick={handleButtonClick}>
            <IonIcon src={EmailIcon} className={`ask-question-button-icon ${props.iconClassName}`} />
            {t("Ask a Question")}
        </IonButton>
   
    );
};

export default AskAQuestionButton;
