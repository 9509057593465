import "./HomePageCarousel.scss";
import React from 'react';
import { ApiCollection } from '../../utils/ApiTypes';
import { CategoryHeader } from "../ContentTiles/CategoryHeader";
import { ContentCarousel } from "../ContentTiles/ContentCarousel";
import AnalyticsService from "../../misc/AnalyticsService";
import { IonItemDivider } from "@ionic/react";
import { useLocation } from "react-router-dom";


interface Props {
    collection: ApiCollection;
    onClick: () => void;
    onSaveChange: (saved: boolean) => void;
}

export const HomePageCarousel: React.FC<Props> = ({collection, onClick, onSaveChange}) => {
    const location = useLocation();

    return (
        <div className="home-page-carousel industry-targeting-row-wrapper">
            <div className="video-listing-row-container">
                <div className="video-listing-row">
                    <CategoryHeader
                        name={collection.title}
                        description={collection.shortDescription ?? ""}
                        path={collection.path}
                        onClick={onClick}
                    />
                    <ContentCarousel onSaveChange={onSaveChange} onClick={() => {
                        onClick();
                        AnalyticsService.trackUserAction("hp_category_item", location.pathname);
                    }} items={collection.items} />
                </div>
                <div className="home-page-line-divider-container">
                    <IonItemDivider className="home-page-line-divider" />
                </div>
            </div>
        </div>
    )
}