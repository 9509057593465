import { ApiTopic, SavedEntityType } from "../../utils/ApiTypes";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import React, { EventHandler, SyntheticEvent, useContext, useState } from "react";
import {Link, useLocation} from "react-router-dom";
import AnalyticsService from "../../misc/AnalyticsService";
import { ApplicationContext } from "../../misc/ApplicationContext";
import "./Tile.scss";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import { SolidColorStyle } from "./ContentStyles/SolidColor/SolidColorStyle";
import { useTranslation } from "react-i18next";


export type TopicTileProps = {
    topic: ApiTopic;
    index?: number;
    className?: string;
    onSaveChange?: (saved: boolean) => void;
    onClick?: EventHandler<any>;
};

function preventDefault(e: SyntheticEvent) {
    //Prevents website from navigating to the link if user is not logged in
    e.preventDefault();
}

function noop() {}

export function TopicTile(props: TopicTileProps) {
    const { topic } = props;
    const topicId = (typeof topic.id  === "string") ? parseInt(topic.id) : topic.id;
    const { currentUser, handleGeneralError, industryCssClass } = useContext(ApplicationContext);
    const { t } = useTranslation();

    const location = useLocation();
    const queryClient = useQueryClient();

    const followTopic = useMutation({
        mutationFn: ({playlistId, isFollowingTopic }: {playlistId: number, isFollowingTopic: boolean}) => { 
            if(isFollowingTopic) {
                return JourneyApiClient.removeSavedContent(SavedEntityType.Collection, playlistId);
            }
            else return JourneyApiClient.saveContent(SavedEntityType.Collection, playlistId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["getSavedContent"]);
        }
    });

    function handleFollowState (isFollowing: boolean, e: SyntheticEvent) {
        e.preventDefault();
        //Adding .toString() to tId to avoid type error
        const topicId: number = ((typeof topic.id) === 'string' ? parseInt(topic.id.toString()) : topic.id as number);
        try {
            followTopic.mutate({playlistId: topicId, isFollowingTopic: isFollowing});
            AnalyticsService.trackUserAction(isFollowing ? "removed_save_topic" : "topic_saved", location.pathname, {topic_id: topic.id });

        } catch (ex) {
            handleGeneralError(`Error ${isFollowing ? "removing": "adding"} a topic from 'My List'`, ex);
        }
    }

    return(
        <div className={"tile-component"}>
            <Link
                onClick={currentUser ? props.onClick ?? noop : preventDefault}
                to={topic.path}
                className={`tile-container ${props.className ?? ''}`}>

                <SolidColorStyle 
                    industryClass={industryCssClass} 
                    handleAddToList={(e) => handleFollowState(false, e)} 
                    handleRemoveFromList={(e) => handleFollowState(true, e)} 
                    isSaved={topic.isSaved} 
                    id={topicId} 
                    thumbnailUrl={topic.thumbnailUrl ?? ""} 
                    tileTitle={t("topic")} 
                    contentTitle={topic.title}/>
            </Link>
        </div>
    )
}