import "./ListSelect.scss";
import * as React from 'react';
import { IonSelect, SelectChangeEventDetail, IonSelectOption } from "@ionic/react";

interface ListSelectProps{
    componentClassName: string;
    selectClassName: string;
    isMobileApp: boolean;
    onIonChange: (e: CustomEvent<SelectChangeEventDetail>) => void;
    value: number | string | null;
    placeholder: string;
    list: any[];
    isListOfLanguages?: boolean;
    positionSide: 'top' | 'right' | 'bottom' | 'left' | 'start' | 'end';
}

export const ListSelect: React.FC<ListSelectProps> = (props: ListSelectProps) => {


    return(
        <div className={`list-select-component ${props.componentClassName}`}>
            <IonSelect  
                interface={props.isMobileApp ? "alert" : "popover"}
                interfaceOptions={{side: props.positionSide}}
                placeholder={props.placeholder}
                onIonChange={props.onIonChange}
                value={props.value}
                className={`${props.selectClassName}`}>
                {
                    props.list.map((el, index) => {
                        const value = props.isListOfLanguages ? el.languageCode : el.id;
                        const displayName = props.isListOfLanguages ? (el.localizedLanguageName || el.name) : el.name;
                        //Not all languages have id, so we use the name instead
                        return <IonSelectOption key={index} value={value}>{displayName}</IonSelectOption>
                    })
                }
            </IonSelect>
        </div>
    )
};
