import { IonButton, IonIcon } from '@ionic/react';
import './CallSupportButton.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneIcon from "../../assets/images/phone-blue-gradient.svg";
import { GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';

interface CallSupportButtonProps {
    onClick: () => void;
    className?: string;
    iconClassName?: string;
    numberOfPhones: number;
    benefitsInfo: GetCompanyBenefitsInfoResponse;
}

const CallSupportButton: React.FC<CallSupportButtonProps> = (props: CallSupportButtonProps) => {

    const { t } = useTranslation();

    return (
        <IonButton
            className={`call-support-button-component ${props.className}`}
            onClick={props.onClick}>
            <IonIcon src={PhoneIcon} className={`call-support-button-icon ${props.iconClassName}`} />
            {props.numberOfPhones === 1 ? <a href={`tel: ${props.benefitsInfo.supportPhones[0].phoneNumber}`}>
                {t("Call Us")} {props.benefitsInfo.supportPhones[0].subtitle}</a>
                : t("Call Us")
            }
        </IonButton>
   
    );
};

export default CallSupportButton;
