import "./SearchCarouselsComponent.scss";
import { useQuery } from "@tanstack/react-query";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { CategoryHeader } from "../../components/ContentTiles/CategoryHeader";
import { ContentCarousel } from "../../components/ContentTiles/ContentCarousel";
import { IonItemDivider } from "@ionic/react";
import { LIVE_STREAM_CATEGORY_NAME } from "../../utils/utils";
import AnalyticsService from "../../misc/AnalyticsService";
import { useLocation } from "react-router";

const SearchCarouselsComponent: React.FC = () => {
    const location = useLocation();
    const categoriesResults = useQuery(
        ["fetchHomeCategories"],
        () => JourneyApiClient.getHomeContent()
    );

    const liveStreamResults = useQuery(
        ["fetchLiveStreams"],
        () => JourneyApiClient.getLiveStreamContent()
    );

    return (
        <div className="search-carousels-component">
        {
            (liveStreamResults.status === "success" && liveStreamResults.data.length > 0) &&
            <div className="video-listing-row-container">
                <div className="video-listing-row">
                    <CategoryHeader
                        name={LIVE_STREAM_CATEGORY_NAME}
                        description={null}
                        onClick={() => {}}
                    />

                    <div className={"industry-targeting-row-wrapper"}>
                        <ContentCarousel onSaveChange={() => {}} onClick={() => {
                            AnalyticsService.trackUserAction("search_livestream_video", location.pathname);
                        }} items={liveStreamResults.data}/>
                    </div>
                </div>
                <div  className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider"/></div>
            </div>
        }
        {
            categoriesResults.status === "success" && 
            categoriesResults.data.videoCategories.map((category, index) => {
                return (
                    <div className={"industry-targeting-row-wrapper"}> 
                        <div className="video-listing-row-container" key={index}>
                            <div className="video-listing-row">
                                <CategoryHeader
                                    name={category.name}
                                    description={category.shortDescription}
                                    path={`/category/${category.id}`}
                                    onClick={() => {}}
                                />
                                <ContentCarousel onSaveChange={() => {}} onClick={() => {
                                    AnalyticsService.trackUserAction("search_category_item", location.pathname);
                                }} items={category.items}/>
                            </div>
                            {
                                index < categoriesResults.data.videoCategories.length - 1 && 
                                <div className="home-page-line-divider-container">
                                    <IonItemDivider className="home-page-line-divider"/>
                                </div>
                            }
                        </div>
                    </div>
                );
            })
        }
        </div>
    );
};

export default SearchCarouselsComponent;
