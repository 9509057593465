import "./RegisterPage.scss";
import React from "react";
import * as Sentry from "@sentry/react";
import { useState, useContext, useEffect } from "react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useHistory } from "react-router-dom";
import { IonPage, IonContent, SelectChangeEventDetail, IonButton } from "@ionic/react";
import DOMPurify from 'dompurify';
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { ApiCompany, ApiCountry, ApiResourcesContentLanguage } from "../../utils/ApiTypes";
import {JourneyApiClient} from '../../utils/JourneyApiClient';
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import Logo from "../../assets/images/logo-journey.svg";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';
import { SignUpFormError } from "../../components/SignUpFormError/SignUpFormError";
import { ListSelect } from "../../components/ListSelect/ListSelect";

export const RegisterCompanySelectorPage: React.FC = () => {

    const { isMobileApp, handleGeneralError, changeLanguage, isMobileWidth } = useContext(ApplicationContext);
    const history = useHistory();
    const [companies, setCompanies] = useState<ApiCompany[] | null>(null);
    const [companyId, setCompanyId] = useState<number | null>(null);
    const [countryId, setCountryId] = useState<string | null>(null);
    const [languageName, setLanguageName] = useState<string | null>(null);
    const [language, setLanguage] = useState<ApiResourcesContentLanguage | null>(null);
    const queryParams = new URLSearchParams(window.location.search);
    const locale = queryParams.get("locale") ?? "en-US";
    const isComponentVisible = useIsComponentVisible();
    const DEFAULT_IMAGE_URL = '/registration-pages/SelfRegistration+-+Journey.png';
    const { t } = useTranslation();

    async function fetchCompanies() {
        const companies = await JourneyApiClient.getCompanies();
        setCompanies(companies);

        return companies;
    }

    async function getLocale(locale: string): Promise<ApiResourcesContentLanguage | undefined> {
        try {
            let language = await JourneyApiClient.getLocale(locale);
            if(language){
                changeLanguage(language)
                return language;
            }
        } catch (e) {
            handleGeneralError("Could not fetch locale", e, undefined, {
                contextName: "Register Company Selector Page", 
                contextData: {
                    functionName: "getLocale",
                    queryParams: queryParams,
                    locale: locale, 
                    acuityError: e,
                    language: language
                } 
            });
        }
    }

    const getLocaleQuery = useQuery<Promise<ApiResourcesContentLanguage | undefined>, unknown, ApiResourcesContentLanguage>
        (["getLocale", locale], () => getLocale(locale!), { enabled: !!locale });

    async function getEnglish(): Promise<ApiResourcesContentLanguage | undefined> {
        try {
            let english = await JourneyApiClient.getLocale("en-US");
            return english;
            
        } catch (e) {
            handleGeneralError("Could not fetch default locale", e, undefined, {
                contextName: "Register Company Selector Page", 
                contextData: {
                    functionName: "getEnglish",
                    acuityError: e,
                    language: language
                } 
            });
        }
    }

    const getEnglishQuery = useQuery<Promise<ApiResourcesContentLanguage | undefined>, unknown, ApiResourcesContentLanguage>
        (["getEnglish"], () => getEnglish());

    async function getResourcesLanguages(countryId: string, companyId: number | null): Promise<ApiResourcesContentLanguage[] | undefined> {
        try {
            let languages = await JourneyApiClient.getResourcesLanguages(companyId || undefined);
            return languages.filter(lang => lang.countryId === countryId);
        } catch (e) {
            handleGeneralError("Could not fetch languages", e, undefined, {
                contextName: "Register Company Selector Page", 
                contextData: {
                    functionName: "getResourcesLanguages",
                    queryParams: queryParams,
                    locale: locale, 
                    countryId: countryId,
                    companyId: companyId,
                    acuityError: e,
                    language: language
                } 
            });
        }
    }

    const resourcesLanguagesQuery = useQuery<Promise<ApiResourcesContentLanguage[] | undefined>, unknown, ApiResourcesContentLanguage[]>
        (["getResourcesLanguages", countryId, companyId], () => getResourcesLanguages(countryId!, companyId), { enabled: !!countryId });

    async function getCompanyCountries(companyId: number, companiesList: ApiCompany[]): Promise<ApiCountry[] | undefined> {
        try {
            const company = companiesList?.find(c => c.id === companyId);
            if(!company) return;
            return await JourneyApiClient.getCompanyCountries(company.code);
        } catch (e) {
            handleGeneralError("Could not fetch countries", e, undefined, {
                contextName: "Register Company Selector Page", 
                contextData: {
                    functionName: "getCompanyCountries",
                    queryParams: queryParams,
                    locale: locale, 
                    companiesList: companiesList,
                    companyId: companyId,
                    acuityError: e,
                    language: language
                } 
            });
        }
    }

    const companyCountriesQuery = useQuery<Promise<ApiCountry[]  | undefined>, unknown, ApiCountry[] >
        (["getCompanyCountries", companyId, companies], () => getCompanyCountries(companyId!, companies!), {enabled: !!companyId && !!companies});

    const submit = async () => {
        const company = companies?.find(c => c.id === companyId);
        if(!language || !company) return;
        changeLanguage(language);
        history.push(`/register/${company?.code}?skipCountrySelection=true`);
    }

    const companiesResult = useQuery(["fetchCompanies", isComponentVisible], fetchCompanies);
    if (companiesResult.status === "error") {
        Sentry.captureException(companiesResult.error);
    }

    let rawMessage: string = t("Welcome to Journey, a benefit that comes at no cost to you and your loved ones. Experience the benefits of proactive mental health content, therapy services, and personalized resources designed to support your well-being. Embrace this opportunity to prioritize your mental health and embark on a transformative journey of self-care and growth. Simply enter your email below to start!");

    const displayMessage = DOMPurify.sanitize(rawMessage);

    function handleCompanyChange(e: CustomEvent<SelectChangeEventDetail>) {
        setCompanyId(e.detail.value);
        resetCountry();
        resetLanguage();
    }

    function handleCountryChange(e: CustomEvent<SelectChangeEventDetail>) {
        setCountryId(e.detail.value);
        resetLanguage();
    }

    function handleLanguageChange(e: CustomEvent<SelectChangeEventDetail>, languageList: ApiResourcesContentLanguage[]) {
        const lang = languageList.find(lang => lang.languageCode === e.detail.value);
        if(lang){
            //TODO: Use localized name whenever localized languages are put in the DB
            setLanguageName(lang.name);
            setLanguage(lang);
        }
    }

    function resetCountry(){
        setCountryId(null);
    }

    function resetLanguage(){
        setLanguageName(null);
    }

    //Disabling continue button if company is not selected, country is not selected and language is not selected
    //If company is selected but no country or languages are available, continue button should be enabled
    let isCompanySelected = companyId !== null;
    let isCountrySelected = (countryId && countryId !== "");
    let isCountryOptionAvailable = isCompanySelected && (companyCountriesQuery.status === "success" && companyCountriesQuery.data.length !== 0);
    let isLanguageSelected = (languageName && languageName !== "");
    let isLanguageOptionAvailable = isCountryOptionAvailable && (resourcesLanguagesQuery.status === "success" && resourcesLanguagesQuery.data.length !== 0);
    let isContinueButtonDisabled = !isCompanySelected || (isCountryOptionAvailable && !isCountrySelected) || (isLanguageOptionAvailable && !isLanguageSelected);

    useEffect(() => {
        //By default making language english if no country or language is available
        if(!isCountryOptionAvailable && !isLanguageOptionAvailable && getEnglishQuery.status === "success" && getEnglishQuery.data)
        setLanguage(getEnglishQuery.data);
    }, [companyId]);


    if(getLocaleQuery.status === "loading") return <PleaseWait/>
    return (
        <IonPage className="register-page-component">

            <IonContent className='register-page-content'>
                <div className='register-page'>
                    <div className='header'>
                        <img src={Logo} className="logo-image" alt="Logo" />
                    </div>
                    <div className='body'>
                        <div className="two-panel-container">
                            <div className="left-panel">
                                <h1 className="welcome">{t("Welcome to Journey!")}</h1>
                                <h3 className="message" dangerouslySetInnerHTML={{ __html: displayMessage }}></h3>
                                {
                                    companiesResult.status === "success" &&
                                    <img
                                        src={`https://journeylive.imgix.net/${process.env.NODE_ENV}${DEFAULT_IMAGE_URL}`}
                                        alt="Registration Page"
                                    />
                                }
                            </div>
                            <div className="right-panel">
                                { companiesResult.status === "loading" && <PleaseWait/> }
                                { companiesResult.status === "error" && <SignUpFormError/> }
                                {
                                    companiesResult.status === "success" && companies &&

                                        <div className="sign-up-form paper-container">
                                            <h3 className="message">{t("Select Your Company")}</h3>

                                            {<ListSelect 
                                                componentClassName={"register-company-selector-list-select-component"} 
                                                selectClassName={"ion-select button-medium"}  
                                                isMobileApp={isMobileApp ?? false} 
                                                onIonChange={handleCompanyChange}
                                                value={companyId} 
                                                placeholder={t("Company")} 
                                                positionSide={isMobileWidth ? "top" : "bottom"}
                                                list={companies}/>}
                                            {companyCountriesQuery.status === "success" && companyCountriesQuery.data.length > 0 &&
                                            <>
                                                <h3 className="message">{t("Select Your Country")}</h3>
                                                <ListSelect 
                                                componentClassName={"register-company-selector-list-select-component"} 
                                                selectClassName={"ion-select button-medium"}  
                                                isMobileApp={isMobileApp ?? false} 
                                                onIonChange={handleCountryChange}
                                                value={countryId} 
                                                placeholder={t("Country")} 
                                                positionSide={isMobileWidth ? "top" : "bottom"}
                                                list={companyCountriesQuery.data}/>
                                            </>}
                                            {countryId && resourcesLanguagesQuery.status === "success" && resourcesLanguagesQuery.data.length > 0 &&
                                            <>
                                                <h3 className="message">{t("Select Your Language")}</h3>
                                                <ListSelect 
                                                    componentClassName={"register-company-selector-list-select-component"} 
                                                    selectClassName={"ion-select button-medium"}  
                                                    isMobileApp={isMobileApp ?? false} 
                                                    onIonChange={(e) => handleLanguageChange(e, resourcesLanguagesQuery.data)}
                                                    value={languageName} 
                                                    placeholder={t("Languages")} 
                                                    isListOfLanguages={true}
                                                    positionSide={isMobileWidth ? "top" : "bottom"}
                                                    list={resourcesLanguagesQuery.data.filter(lang => lang.countryId === countryId.toString())}/>
                                            </>}
                                            <IonButton type='submit' className='paper-submit-button-variation' onClick={submit} disabled={isContinueButtonDisabled}>
                                                {t('Continue')}
                                            </IonButton>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}
