import { useContext, useEffect, useState } from "react";
import "./InvitationForm.scss";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { IonButton, IonIcon, IonInput, IonItem } from "@ionic/react";
import InviteIcon from "../../assets/images/invite-white.svg";
import Checkmark from "../../assets/images/checkmark-green.svg";
import InviteIconRed from "../../assets/images/invite-red.svg";
import CircularProgress from '@mui/material/CircularProgress';
import { ApiInvitation, ApiUser } from "../../utils/ApiTypes";
import { useTranslation } from "react-i18next";
import AnalyticsService from "../../misc/AnalyticsService";
import { useLocation } from "react-router-dom";
import { HasFeatureEnabled } from "../Feature/Feature";


interface InvitationFormProps {
    user: ApiUser;
    isComponentVisible: boolean;
}

export function InvitationForm(props: InvitationFormProps) {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const {handleGeneralError, handleGeneralMessage, handleUserError, currentUser} = useContext(ApplicationContext);
    const { t } = useTranslation();
    const location = useLocation();
    const [formValues, setFormValues] = useState({
        firstName: null,
        lastName: null,
        email: null,
        message: null,
    });

    useEffect(() => {
        if(!props.isComponentVisible) return;
        getInvitations();
    }, [props.isComponentVisible]);

    async function getInvitations(): Promise<ApiInvitation[]> {
        return await JourneyApiClient.getUserInvitation();
    }
    const invitationsQuery = useQuery<Promise<ApiInvitation[]>, unknown, ApiInvitation[]>
    (["getUserInvitation", props.isComponentVisible], getInvitations);

    function handleValueChange(name: string, value: string | null, event?: any) {
        setFormValues({...formValues, [name]: value});
    }

    async function handleInvitation() {
        if(currentUser?.company.type === "trial"){
            handleUserError(t("This feature is not available for Trial users."), " ");
            return;
        } 
        if (!formValues.firstName || formValues.firstName === "") {
            handleUserError("Incorrect value for First Name", "First Name field can not be empty");
            return;
        }
        if (!formValues.lastName || formValues.lastName === "") {
            handleUserError("Incorrect value for Last Name", "Last Name field can not be empty");
            return;
        }
        if (!formValues.email || formValues.email === "") {
            handleUserError("Incorrect value for Email", "Email field can not be empty");
            return;
        }
        if (formValues.email && !validateEmail(formValues.email)) {
            handleUserError("Invalid Email format", "Invalid Email format");
            return;
        }
        try {
            setIsLoading(true);
            await JourneyApiClient.inviteFriendAndFamily(formValues.email, formValues.firstName, formValues.lastName, formValues.message);
            handleGeneralMessage("Success", "Invitation sent!");
            setFormValues({
                firstName: null,
                lastName: null,
                email: null,
                message: null
            });
            trackInvitationSent();
            await queryClient.invalidateQueries({ queryKey: ["getUserInvitation"]});
        } catch (e) {
            handleGeneralError("Invitation failed", e);
        }
        setIsLoading(false);
    }

    function validateEmail(email: string) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }


    async function resendInvitation(userToResendInvite: ApiUser) {
        try {
            await JourneyApiClient.resendInviteFriendFamilyEmail(userToResendInvite.id);
            handleGeneralMessage("Success", "Invitation sent!");
            trackResendInvitation();
        } catch (e) {
            handleGeneralError("Invitation failed", e);
        }
    }


    async function trackInvitationSent() {
        await AnalyticsService.trackUserAction("invite_form_invitation", location.pathname);
    }

    async function trackResendInvitation() {
        await AnalyticsService.trackUserAction("invite_form_resend_invitation", location.pathname);
    }


    function noop() {};

    return(
        <div className={"invitation-form-component"}>
            <IonItem className={"title-item subtitle1-variant title"}>
                {t("Invite friends and family")}
            </IonItem>
            <div className={"header-text caption"}>
                {props.user && HasFeatureEnabled("has-eap") ?
                    t("Share Journey Proactive EAP with your loved ones completely for free.")
                    :
                    t("Your friends and family can use all of Journey’s features completely for free.")
                }
            </div>
            <IonItem className={"form-item"}>
                <IonInput
                    className={"input name body-small"}
                    placeholder={`${t("First Name*") ?? "First Name"}`}
                    value={formValues.firstName}
                    onIonChange={e => handleValueChange("firstName", e.detail.value ?? null)}
                />
            </IonItem>
            <IonItem className={"form-item"}>
                <IonInput
                    className={"input lastname body-small"}
                    placeholder={`${t("Last Name*") ?? "Last Name"}`}
                    value={formValues.lastName}
                    onIonChange={e => handleValueChange("lastName", e.detail.value ?? null)}/>
            </IonItem>
            <IonItem className={"form-item"}>
                <IonInput
                    className={"input email body-small"}
                    placeholder={`${t('email') ?? "Email"}*`}
                    value={formValues.email}
                    onIonChange={e => handleValueChange("email", e.detail.value ?? null)}/>
            </IonItem>
            <IonItem className={"form-item"}>
                <IonInput
                    className={"input message body-small"}
                    placeholder={t("Add a message") ?? 'Add a message'}
                    value={formValues.message}
                    onIonChange={e => handleValueChange("message", e.detail.value ?? null)}/>
            </IonItem>
            <IonButton onClick={handleInvitation} className={"send-invite-button button-medium-variant"}>
                {t("Send Invite")} <IonIcon className={"invite-icon"} src={InviteIcon}/>
            </IonButton>
            {invitationsQuery.status === "success" && invitationsQuery.data && invitationsQuery.data.length > 0 && <>
                <IonItem className={"title-item subtitle1-variant title"}>
                    {t('Invitations')}
                </IonItem>
                <div className={`invitation-wrapper ${invitationsQuery.data && invitationsQuery.data.length > 2 && "overflow"}`}>
                    {invitationsQuery.data.map((inv: ApiInvitation, i: number) => {
                        let hasAcceptedInvitation: boolean = inv.invitee.lastLoginDate !== null && inv.invitee.lastLoginDate !== undefined;
                        return(
                            <div className={"invitation-container"} key={i}>
                                <IonItem className={"invitation-item button-medium"}>
                                    {`${inv.invitee.firstName} ${inv.invitee.lastName}`}
                                </IonItem>
                                <IonButton
                                    className={`invitation-button button-small-variant ${hasAcceptedInvitation ? "green" : "coral"}`}
                                    disabled={hasAcceptedInvitation || isLoading}
                                    onClick={hasAcceptedInvitation ? noop : () => resendInvitation(inv.invitee)}>
                                    <IonIcon className={"invitation-icon"} src={hasAcceptedInvitation ? Checkmark : InviteIconRed}/>
                                    {isLoading ? <CircularProgress size={30} /> : hasAcceptedInvitation ? t("accepted") : t("resend")}
                                </IonButton>
                            </div>
                        )
                    })}
                </div>
            </>}

        </div>
    )
}
