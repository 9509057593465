import React, { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import './ProactiveClinicialCareModal.scss';
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import { ApiAcuityAppointment, GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';
import { useQuery } from '@tanstack/react-query';
import { ProactiveClinicialCareModal } from './ProactiveClinicialCareModal';
import AnalyticsService from '../../misc/AnalyticsService';
import { useLocation } from 'react-router';
import { CheckinState } from '../CheckinGraph/CheckinGraph';
import { HasFeatureEnabled } from "../Feature/Feature";

interface ProactiveClinicialCareComponentProps {
    checkInStateValue: CheckinState;
    checkinRating?: number;
}

export const ProactiveClinicialCareComponent: React.FC<ProactiveClinicialCareComponentProps> = (props: ProactiveClinicialCareComponentProps) => {

    const { handleGeneralError, language, currentUser } = useContext(ApplicationContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const location = useLocation();

    const isWpo = HasFeatureEnabled("talk-to-someone-schedule-wpo-appointment");
    const hasProactiveClinicalCare = HasFeatureEnabled("proactive-clinical-care");

    async function getClinicalCareModalOpenValue(checkinState: string, checkInRating: number | undefined): Promise<boolean | undefined> {
        try {
            let isOpen = false;
            if(!hasProactiveClinicalCare) return isOpen;
            if(checkinState === "checkedin" && checkInRating){
                //checkinRating === 2 means that is bad checkin answer 
                //checkinRating === 3 means that is okay checkin answer
                //if not a bad or ok answer we don't log the activity
                if(checkInRating >= 4) return isOpen;
                //We log the checkin and then we check if the modal should be open
                isOpen = await JourneyApiClient.logClinicalCareCheckin(checkInRating);
            } else {
                //We check if the modal should be open because of other check ins from the past and outside of the app (Slack/Teams app)
                isOpen = await JourneyApiClient.getClinicalCareModalStatus();
            }
            if(isOpen) trackOpenProactiveClinicialCareModal();
            setIsModalOpen(isOpen);
            return isOpen;
        } catch (e) {
            handleGeneralError("Could not fetch modal status", e, undefined, { 
                contextName: "Get Clinical Care Modal Status ProactiveClinicialCareComponent", 
                contextData: {
                    acuityError: e,
                    userId: currentUser?.id,
                    language: language
                } 
            });
        }
    }
    const clinicalCareModalQuery = useQuery<Promise<boolean | undefined>, unknown, boolean>
        (["getClinicalCareModalStatus", props.checkInStateValue, props.checkinRating], 
        () => getClinicalCareModalOpenValue(props.checkInStateValue, props.checkinRating));

    async function getAppointment(): Promise<ApiAcuityAppointment | undefined> {
        const nowDate = new Date().toISOString();
        try {
            if(isWpo) {
                return await JourneyApiClient.getScheduledWpoAppointment();
            } else {
                return await JourneyApiClient.getScheduledAppointment(nowDate);
            }
        } catch (e) {
            handleGeneralError("Could not fetch appointment", e, undefined, { 
                contextName: "Get Appointment ProactiveClinicialCareComponent", 
                contextData: {
                    nowDate: nowDate,
                    acuityError: e,
                    userId: currentUser?.id,
                    language: language
                } 
            });
        }
    }
    const appointmentsQuery = useQuery<Promise<ApiAcuityAppointment | undefined>, unknown, ApiAcuityAppointment>
        (["getScheduledAppointment"], getAppointment);


    async function fetchResources(): Promise<GetCompanyBenefitsInfoResponse | undefined> {
        try {
            return await JourneyApiClient.getCompanyBenefitsInfo();
        } catch (e) {
            handleGeneralError("Could not fetch company benefits", e, undefined, { 
                contextName: "Get Company Benefits ProactiveClinicialCareComponent", 
                contextData: {
                    userCompany: currentUser?.company,
                    userId: currentUser?.id,
                    language: language
                } 
            });
        }
    }
    const benefitsInfoResults = useQuery<Promise<GetCompanyBenefitsInfoResponse | undefined>, unknown, GetCompanyBenefitsInfoResponse>
        (["fetchBenefitsInfo", isModalOpen], fetchResources);


    function trackOpenProactiveClinicialCareModal() {
        AnalyticsService.trackUserAction("proactive_clinicial_care_open", location.pathname, null);
    }

    return (
        <div className="proactive-clinicial-care-component">
            {appointmentsQuery.status !== "loading" && benefitsInfoResults.status === "success" && benefitsInfoResults.data &&
            <ProactiveClinicialCareModal 
                handleClose={() => setIsModalOpen(false)}
                isOpen={isModalOpen} 
                appointment={appointmentsQuery.data} 
                benefits={benefitsInfoResults.data} 
            />}
        </div>
    );
};

