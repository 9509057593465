import { ApiArticle, ApiAsset, CollectionType, MediaType } from "../utils/ApiTypes";


// SAME value as the file of AlgoliaSyncService.ts 
const playlistObjectIDPrefix = 'playlist::';
const videoObjectIDPrefix = 'video::';
const articleObjectIDPrefix = 'article::';


/**
 * Helper class to create filter strings for Algolia search queries. We format the filter as Algolia needs it to be.
 * Specifically we filter through objectID, to see the format this is done look at the Aloglia Sync Service.
 * We need in the frontend since its the least expensive (computational wise) place to do this. 
 * For more information of algolia filtering visit: https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/#filter-by-attributes
 */
export default class AlgoliaFilterHelper {

    static createFilterString(content: ApiAsset, operator: "OR" | "AND"): string {
        const filter = this.iterateOverContent(content, operator);
        return `(${filter.join(` ${operator} `)})`;
    }

    static iterateOverContent(content: ApiAsset, operator: "OR" | "AND"): string[] {
        let filter: string[] = [];

        switch (content.type) {
            case CollectionType.Playlist:
                const videos: string[] = content.items.map((v) => AlgoliaFilterHelper.iterateOverContent(v, operator)).flat();
                filter = filter.concat(videos);
                filter.push(`objectID:'${playlistObjectIDPrefix}${content.id}'`);
                break;
            case CollectionType.Topic:
                const topicContent: string[] = (content.items.map((t) => AlgoliaFilterHelper.iterateOverContent(t, operator))).flat();
                filter = topicContent;
                break;
            case MediaType.Video:
                filter.push(`objectID:'${videoObjectIDPrefix}${content.id}'`);
                break;
            case MediaType.Audio:
                filter.push(`objectID:'${videoObjectIDPrefix}${content.id}'`);
                break;
            case MediaType.Article:
                filter.push(`objectID:'${articleObjectIDPrefix}${content.id}'`);
                break;
        }
        
        return filter;
    }
}